





































































































































































































































































import { defineComponent, reactive, ref, set, toRefs, computed, watch } from '@vue/composition-api';
import { ObjectId } from 'bson';
import axios from 'axios';
import store, { useUserGetters, useUserState, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import calculateProgramCompletion from '@/utils/calculateProgramCompletion';
import { useVideoAsk } from '@/composables/useVideoAsk';
import { CommentAuthor } from '@/@types/comment.d';
import { OperationMode } from '@/constants/operationMode';
import { StudentStatus, getStudentStatus } from './studentStatus';
import { OFFER_PAYLOAD } from './components/const';
import Dashboard from './components/Dashboard.vue';

export default defineComponent({
  name: 'Monitor',
  components: {
    GuideBar: () => import('./Bar.vue'),
    AOverlayLoader: () => import('@/components/atoms/a-overlay-loader.vue'),
    Loading: () => import('@/components/Loading.vue'),
    ListView: () => import('./components/TableView.vue'),
    Guide: () => import('../Guide/Guide.vue'),
    OfferCard: () => import('./components/OfferCard.vue'),
    SignOffIntern: () => import('./components/SignOffIntern.vue'),
    Dashboard
  },

  setup(_, ctx) {
    const state = reactive({
      students: [] as Record<string, any>[],
      programs: [] as Record<string, any>[],
      selectedStudent: { adks: [] } as Record<string, any>,
      activityFilter: '',
      currentView: 'dashboard',
      monitorRoute: {
        page: 1,
        monitorOrganizerView: false
      },
      studentsLength: 0
    });
    const signOffDialog = ref(false);
    const signedOffPopup = ref(false);
    const isLoading = ref(false);
    const bar = ref();

    const { user } = useUserState(['user']);
    const { getObjectId, getUser } = useUserGetters(['getObjectId', 'getUser']);
    const { makeOffer } = useUserActions(['makeOffer']);
    const loadMore = ref(false);
    const allStudentsFetched = ref(false);
    const isFiltering = ref(false);
    const skip = ref(0);
    const limit = ref(1000);
    const disableLoadMore = ref(false);

    const filteredStudents = ref(null);

    const handleFilteredStudents = students => {
      filteredStudents.value = students;
    };

    const initialLimit = ref(1000);
    const totalLimit = ref(2500);
    const isLoadingMore = ref(false);
    const APIUrl = ref('');

    async function loadRemainingData() {
      if (isLoadingMore.value) return;

      isLoadingMore.value = true;
      const remainingParams = `&skip=${initialLimit.value}&limit=${
        totalLimit.value - initialLimit.value
      }`;

      try {
        const remainingRes = await axios.get(APIUrl.value + remainingParams, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (remainingRes.data?.data?.monitoredStudents) {
          state.students = [...state.students, ...remainingRes.data.data.monitoredStudents];
        }
        allStudentsFetched.value = true;
      } catch (error) {
        console.error('Error loading remaining data:', error);
      } finally {
        isLoadingMore.value = false;
      }
    }

    async function loadData(val) {
      let filterVal = val;
      allStudentsFetched.value = false;
      isFiltering.value = true;

      if (ctx.root.$route.query.program) {
        if (!filterVal)
          filterVal = JSON.parse(
            localStorage.getItem(`setInternFilter_${ctx.root.$route.query.program}`)
          )?.value;
        APIUrl.value = `${process.env.VUE_APP_MONITOR}?owner_id=${user.value?._id}&program_id=${
          ctx.root.$route.query.program
        }&filter=${filterVal || 'current_students'}&skip=0&limit=${initialLimit.value}`;
      } else if (ctx.root.$route.query.organization) {
        if (!filterVal)
          filterVal = JSON.parse(localStorage.getItem(`setInternFilter_General`))?.value;
        APIUrl.value = `${process.env.VUE_APP_MONITOR}?owner_id=${user.value?._id}&org_id=${
          ctx.root.$route.query.organization
        }&filter=${filterVal || 'current_students'}&skip=0&limit=${initialLimit.value}`;
      } else {
        if (!filterVal)
          filterVal = JSON.parse(localStorage.getItem(`setInternFilter_General`))?.value;
        APIUrl.value = `${process.env.VUE_APP_MONITOR}?owner_id=${user.value?._id}&filter=${
          filterVal || 'current_students'
        }&skip=0&limit=${initialLimit.value}`;
      }

      if (!val) store.dispatch(`tools/${ActionTypes.showLoader}`);
      const monitorRes = await axios.get(APIUrl.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });

      if (monitorRes.data) {
        if (monitorRes.data?.data?.monitoredStudents?.length >= 1) {
          state.students = monitorRes.data?.data?.monitoredStudents;
          state.studentsLength = monitorRes.data?.data?.studentsLength;

          if (state.studentsLength > initialLimit.value) {
            loadRemainingData();
          }
        }
        if (monitorRes.data?.data?.programDocs >= 1) {
          state.programs = monitorRes.data?.data?.programDocs;
        }
        if (state.students?.length >= 1) {
          state.selectedStudent = {
            ...state.students[0]
          };
        }
        if (monitorRes.data?.data?.monitoredStudents?.length < 1) {
          disableLoadMore.value = true;
        }
        if (!val) store.dispatch(`tools/${ActionTypes.showLoader}`);
        isFiltering.value = false;
      }
    }

    watch(state, val => {
      if (val.selectedStudent === null) {
        state.selectedStudent = { ...state.students[0] };
      }
    });

    async function loadMoreData() {
      isLoading.value = true;
      loadMore.value = true;
      skip.value += limit.value;
      await loadData(bar.value?.currentInternFilter?.value);
      loadMore.value = false;
      isLoading.value = false;
    }

    const clearValue = () => {
      skip.value = 0;
      state.students = [];
      state.programs = [];
      disableLoadMore.value = false;
    };

    const studentInfo = student => [
      `${student.grade}th Grade`,
      student.school.name,
      student.program
    ];

    const errorCode = ref(0);
    const errorMessage = '';

    const viewValue = ref(0);

    async function resolveStudentApplication(
      studentId: ObjectId,
      accept: boolean,
      newOffer?: Record<string, boolean | number | string | undefined>
    ) {
      const selectedStudent = state.students.find(student => student._id === studentId);
      const offerDetails = { ...newOffer };
      const rejectedOfferDetails = { ...newOffer };
      if (!selectedStudent) return;
      selectedStudent.showMakeOfferDialog = true;
      selectedStudent.isMakingOffer = true;
      selectedStudent.makingOfferFailed = false;
      set(selectedStudent, 'loadingResolveApplication', true);
      // replace presets with custom values if they are entered
      if (accept) {
        ['InternshipProject', 'Position', 'Compensation'].forEach(field => {
          const fieldName = field[0].toLowerCase() + field.slice(1);
          offerDetails[fieldName] =
            offerDetails[fieldName] === 'custom' ? undefined : offerDetails[fieldName];
          if (offerDetails[`custom${field}`]) {
            offerDetails[fieldName] = offerDetails[`custom${field}`];
            offerDetails[`custom${field}`] = true;
          } else {
            offerDetails[`custom${field}`] = false;
          }
        });
      }
      try {
        const result: any = await makeOffer({
          student_id: selectedStudent._id,
          accept,
          offer_details: accept ? offerDetails : rejectedOfferDetails
        });
        if (result) {
          set(selectedStudent, 'applicationStatus', accept ? 'accepted' : 'rejected');
          set(
            selectedStudent,
            'studentStatus',
            accept ? 'organizerMadeOffer' : 'organizerShelvedStudent'
          );
          selectedStudent.makingOfferFailed = false;

          selectedStudent.makingOfferFinishedMessage = accept
            ? 'Your offer has been sent!'
            : 'Thank you for your decision.';
        } else {
          selectedStudent.makingOfferFailedReason = result.error ?? '';
          selectedStudent.makingOfferFailed = true;
        }
        selectedStudent.isMakingOffer = false;
        if (selectedStudent.isRejecting) {
          selectedStudent.isRejecting = false;
          selectedStudent.showMakeOfferForm = false;
        }
        errorCode.value = result.statusCode;
        set(selectedStudent, 'loadingResolveApplication', false);
      } catch (error) {
        selectedStudent.showMakeOfferDialog = true;
        selectedStudent.makingOfferFailed = true;
      }
    }

    const handleMonitorButtonClick = ({ page }) => {
      state.currentView = 'monitor';
      state.monitorRoute.page = page;
      state.monitorRoute.monitorOrganizerView = true;
    };

    const handleExitMonitor = () => {
      state.currentView = 'participant';
    };

    const handleActivityNewPage = newPage => {
      state.monitorRoute.page = newPage;
    };

    const IndexStudentValue = ref(0);

    const setIndexValue = IndexValueStudent => {
      IndexStudentValue.value = IndexValueStudent;
    };

    const handleMakeOfferDialogClose = () => {
      if (state.selectedStudent) {
        state.selectedStudent.showMakeOfferForm = false;
        state.selectedStudent.showMakeOfferDialog = false;
      }
    };

    const handleMakeOfferDialogVisibleChange = (visible: boolean) => {
      if (state.selectedStudent) {
        state.selectedStudent.showMakeOfferDialog = visible;
      }
    };

    const currentUser = computed(() => {
      const userInfo: CommentAuthor = {
        _id: user.value?.data?._id,
        firstName: user.value?.data?.firstName,
        lastName: user.value?.data?.lastName,
        profile: user.value?.data?.profile
      };

      return userInfo;
    });

    const operationMode = computed(() => {
      return OperationMode.Monitor;
    });

    const userType = computed(() => {
      let userType: 'organizer' | 'stakeholder' | 'participant' | '' = 'stakeholder';

      if (ctx.root.$route.query && ctx.root.$route.query.program) {
        userType = 'organizer';
      }
      return userType;
    });
    // useVideoAsk(user, operationMode, userType);

    function openSignOffDialog() {
      signOffDialog.value = true;
    }

    function openSignOffPopup() {
      signedOffPopup.value = true;
    }

    const handleCloseParticipant = () => {
      state.currentView = 'dashboard';
      state.selectedStudent = null;
    };

    const handleViewChange = (view, student) => {
      state.currentView = view;
      if (student) {
        state.selectedStudent = student;
      }
    };

    const sidebarExpanded = ref(true);

    const handleSidebarExpand = expanded => {
      sidebarExpanded.value = expanded;
    };

    // const filteredStudents = computed(() => {
    //   if (!bar.value?.filteredStudents) {
    //     return state.students;
    //   }
    //   return bar.value.filteredStudents;
    // });

    const formatTotalTime = adks => {
      if (!adks || !Array.isArray(adks)) return 'No time spent';

      const totalMinutes = adks.reduce((total, adk) => {
        return total + (typeof adk?.timeTaken === 'number' ? adk.timeTaken : 0);
      }, 0);

      if (totalMinutes === 0) return 'No time spent';

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      if (hours === 0) {
        return `${minutes}m`;
      }
      return `${hours}h ${minutes}m`;
    };

    return {
      ...toRefs(state),
      loadData,
      openSignOffPopup,
      openSignOffDialog,
      signOffDialog,
      studentInfo,
      setIndexValue,
      calculateProgramCompletion,
      resolveStudentApplication,
      viewValue,
      handleMonitorButtonClick,
      handleExitMonitor,
      handleActivityNewPage,
      errorCode,
      errorMessage,
      IndexStudentValue,
      handleMakeOfferDialogClose,
      handleMakeOfferDialogVisibleChange,
      signedOffPopup,
      bar,
      loadMoreData,
      clearValue,
      isLoading,
      user,
      allStudentsFetched,
      isFiltering,
      skip,
      limit,
      disableLoadMore,
      handleCloseParticipant,
      handleViewChange,
      sidebarExpanded,
      handleSidebarExpand,
      filteredStudents,
      handleFilteredStudents,
      isLoadingMore,
      loadRemainingData,
      APIUrl,
      formatTotalTime
    };
  }
});
